.container{
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    min-height: 640px;
    background-size: 100% auto;
}
.navbar{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    padding-left: 2%;
    position: relative;
}
.navbar img{
    width: 48px;
    height: 43px;
}
.navbar h2{
    margin-left: 10px;
    cursor: pointer;
}
.navbar button{
    border: none;
    font-size: 17px;
    padding: 5px 20px;
    border-radius: 20px;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    right: 2%;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.section{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
}
.section1{
    height: 550px;
    flex: 0.35;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section2{
    flex: 0.65;
    margin-top: -5%;
}
.section1 img{
    width: 400px;
}
.section2 form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;
    padding-left: 20px;
}
form label img{
    width: 30px;
    margin-right: 10px;
}
form label{
    font-size: 21px;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
}
form input{
    width: 90%;
    border: none;
    font-size: 17px;
    padding: 7px;
    margin-top: 10px;
    border-radius: 5px;
    border: 2px solid #FBDD97;
    color: #896118;
    font-weight: 600;
    outline: none;
}
.validIcon{
    position: absolute;
    top: 145%;
    left: 86%;
    z-index: 200;
}
form input:focus{
    border: 2px solid #896118;
}
.ipfsSection{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-top: -3.5%;
}
.ipfsSection label img{
    width: 30px;
    margin-right: 10px;
}
.ipfsSection label{
    font-size: 21px;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
}
.validIcon2{
    position: absolute;
    top: 145%;
    left: 86%;
    z-index: 200;
}
.ipfsSection input{
    width: 90%;
    border: none;
    font-size: 17px;
    padding: 7px;
    margin-top: 10px;
    border-radius: 5px;
    border: 2px solid #FBDD97;
    color: #896118;
    font-weight: 600;
    outline: none;
}
.ipfsSection input:focus{
    border: 2px solid #896118;
}
.ipfsSection button{
    width: 20%;
    border: none;
    font-size: 18px;
    padding: 5px;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.statusMessage{
    color: green;
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
}
.errorStatusMessage{
    color: red;
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
}
input[readonly]
{
    background-color:rgb(233, 233, 233);
    color: rgb(121, 121, 121);
    border: 2px solid rgb(184, 184, 184);
}
button[readonly]
{
    background-color:rgb(233, 233, 233);
    color: rgb(121, 121, 121);
}
button:active{
    background-color: #896118;
    color: #FBDD97;
}
button:disabled{
    background-color:rgb(233, 233, 233);
    color: rgb(121, 121, 121);
    cursor: default;
}