.section{
    outline: none;
    border: none;
    display: flex;
    z-index: 100;
}
.section1{
    flex: 0.4;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 20px;
}
.section1 h3{
    margin-top: 10px;
}
.section1 span{
    font-size: 13px;
    margin-top: 10px;
}
.otpInput input{
    height: 35px;
    margin-top: 25px;
    background-color: #D9D9D9;
    box-shadow: 0px 3px 3px rgb(172, 172, 172);
    border: none;
    border-radius: 5px;
    font-size: 17px !important;
    margin-right: 10px !important;
    color: #896118;
}
.section1 button{
    margin-top: 25px;
    font-size: 16px;
    padding: 7px;
    border: none;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    border-radius: 7px;
    cursor: pointer;
}
.otpContainer{
    display: flex !important;
    /* justify-content: space-around; */
}
.sendOtpAgain{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}
.sendOtpAgain h4{
    font-size: 13px;
    font-weight: 600;
}
.sendOtpAgain p{
    font-size: 13px;
}
.section2{
    flex: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section2 img{
    width: 100%;
}
button:active{
    background-color: #896118;
    color: #FBDD97;
}