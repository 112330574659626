.container{
    width: 100%;
    height: 100vh;
    min-width: 1300px;
    min-height: 640px;
}
.navbar{
    width: 100%;
    height: 10%;
    background-color: rgb(255, 255, 255);
    padding-left: 2%;
    display: flex;
    align-items: center;
    box-shadow: rgba(199, 199, 199, 0.861) 0px 2px 1px 0px, rgba(184, 184, 185, 0.906) 0px 1px 1px 1px;
}
.navbar img{
    width: 48px;
    height: 43px;
}
.navbar h2{
    margin-left: 10px;
}
.divider{
    width: 2px;
    height: 80%;
    background-color: #949494;
    margin-left: 3%;
    margin-right: 3%;
}
.navIcon{
    width: 30px !important;
    height: 30px !important; 
}
.navbar span{
    font-size: 16px;
    margin-right: 2%;
    margin-left: .5%;
    cursor: pointer;
    font-weight: 600;
}
.userBox{
    width: 200px;
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.user{
    font-size: 21px;
    background-color: #EB5D5D;
    color: white;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.userBox button{
    border: none;
    font-size: 17px;
    padding: 5px 20px;
    border-radius: 20px;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.main{
    width: 100%;
    height: 90%;
}
.fileSection{
    width: 50%;
    height: 170px;
    background-color: #FBDD97;
    margin: 3% auto;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(142, 142, 142, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.fileSectionChild{
    width: 100%;
    height: 100%;
    border: 1px dotted #896118;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.uploadSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.upload{
    margin-top: 10px;
    border: none;
    font-size: 15px;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #896118;
    font-weight: 600;
    cursor: pointer;
}
a{
    text-decoration: none;
    color: black;
}
.setHashSection{
    width: 50%;
    height: 110px;
    background-color: #FBDD97;
    margin: 3% auto;
    border-radius: 5px;
    box-shadow: rgba(142, 142, 142, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.setHashSection h3{
    color: #896118;
    font-size: 17px;
}
.container button{
    display: flex;
    justify-content: center;
    align-items: center;
}
.container button:disabled{
    background-color: #ebebeb;
    color: rgb(169, 169, 169);
}
.getHashSection h3{
    color: #896118;
    font-size: 17px;
}
.getHashSection{
    width: 50%;
    height: auto;
    padding: 20px;
    background-color: #FBDD97;
    margin: 3% auto;
    border-radius: 5px;
    box-shadow: rgba(142, 142, 142, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hashSection{
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.hashSection input{
    border: none;
    font-size: 14px;
    padding: 3px 5px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #896118;
    color: #896118;
    font-weight: 600;
    outline: none;
    font-weight: 500;
}
.copyIcon1{
    width: 18px;
    position: absolute;
    top: 28%;
    left: 93%;
    cursor: pointer;
}
.copyIcon2{
    width: 18px;
    position: absolute;
    top: 85%;
    left: 93%;
    cursor: pointer;
}
.getHashLabels{
    font-size: 13px;
    font-weight: 600;
    color: #896118;
    margin-top: 10px;
    margin-bottom: -5px;
}
.walletStatus{
    margin-left: 7px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.userStatus{
    font-size: 11px !important;
    font-weight: 400 !important;
}
.accountAdd{
    color: rgb(0, 0, 0);
    font-size: 15px !important;
    font-weight: 400 !important;
    position: absolute;
    left: 45%;
}
.invalidFile{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.invalidFile img{
    width: 80px;
    height: 80px;
}
.divider2{
    width: 1px;
    height: 80%;
    background-color: #896118;
}
.invalidInfo p{
    color: #896118;
    font-weight: 600;
}
.invalidInfo button{
    margin-top: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    color: #896118;
    padding: 5px 9px;
    background-color: transparent;
    border: 2px solid #896118;
    border-radius: 5px;
    cursor: pointer;
}
.invalidInfo button img{
    width: 17px;
    height: 17px;
    margin-left: 5px;
}
.downloadFile{
    display: flex;
    justify-content: center;
    align-items: center;
}
.downloadFile button{
    border: none;
    font-size: 17px;
    padding: 10px 15px;
    border-radius: 3px;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.downloadFile img{
    width: 30px;
    margin-left: 10px;
}
input{
    padding-right: 43px !important;
}