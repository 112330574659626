.container{
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    min-height: 640px;
    display: flex;
}
.section1{
    flex: 0.30;
} 
.section2{
    flex: 0.70;
    background-image: url("../../assets/loginPage/page-background.png");
    background-size: 110% auto;
    background-repeat: no-repeat;
    background-position: left center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section2 span{
    font-size: 70px;
    color: #896118;
    font-weight: 700;
    margin-left: 20px;
}
.header{
    width: 100%;
    height: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header img{
    width: 10%;
}
.header h2 {
    font-size: 25px;
    margin-left: 10px;
    font-weight: 700;
}
.verify{
    width: 100%;
    height: 10%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.verify button{
    width: 80%;
    height: 70%;
    background-color: #896118;
    border: none;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    border: 2px solid #896118;
    border-radius: 35px;
    cursor: pointer;
}
.main{
    width: 100%;
    height: 65%;
}
.main h2{
    font-size: 25px;
    padding-left: 10%;
    padding-top: 5%;
    font-weight: 600;
}
.main form{
    margin-top: 10%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.eyeIcon{
    position: absolute;
    top: 57%;
    right: 12%;
    width: 25px;
    cursor: pointer;
}
.main label{
    margin-bottom: 1%;
    font-weight: 600;
    font-size: 17px;
}
.main input{
    width: 90%;
    font-size: 17px;
    padding: 9px;
    margin-bottom: 5%;
    border: none;
    border: 2px solid #FBDD97;
    border-radius: 7px;
    background-color: white;
    outline: none;
}
.main input:focus{
    border: 2px solid #896118;
}
.main button{
    margin-top: 20px;
    width: 90%;
    font-size: 18px;
    padding: 5px;
    border: none;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.main button:active{
    background-color: #896118;
    color: #FBDD97;
}
.main p{
    font-size: 14px;
    text-align: center;
    margin-top: 2%;
}
.main a{
    text-decoration: none;
    color: #896118;
}
.footer{
    width: 100%;
    height: 12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer p{
    margin-bottom: 2%;
}
.divider{
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.divider div{
    width: 30%;
    height: 2px;
    background-color: rgb(154, 154, 154);
}
.divider span{
    font-size: 13px;
    padding: 0px 10px;
}
.forgotPassword{
    margin-top: -15px;
    display: flex;
    width: 90%;
    font-size: 11px;
    justify-content: flex-end;
}