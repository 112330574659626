@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
body{
    background-image: url(/static/media/background.5035718f.jpg);
    background-size: 100% auto;
}
.App{
    margin: 0px auto;
    width: 100%;
    min-height: 100vh;
    background-size: 100% auto;
}
.loginPage_container__2xdLK{
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    min-height: 640px;
    display: flex;
}
.loginPage_section1__3uWuU{
    flex: 0.30 1;
} 
.loginPage_section2__kOUaG{
    flex: 0.70 1;
    background-image: url(/static/media/page-background.3573b3e3.png);
    background-size: 110% auto;
    background-repeat: no-repeat;
    background-position: left center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginPage_section2__kOUaG span{
    font-size: 70px;
    color: #896118;
    font-weight: 700;
    margin-left: 20px;
}
.loginPage_header__1_Lsb{
    width: 100%;
    height: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loginPage_header__1_Lsb img{
    width: 10%;
}
.loginPage_header__1_Lsb h2 {
    font-size: 25px;
    margin-left: 10px;
    font-weight: 700;
}
.loginPage_verify__aoWt8{
    width: 100%;
    height: 10%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginPage_verify__aoWt8 button{
    width: 80%;
    height: 70%;
    background-color: #896118;
    border: none;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    border: 2px solid #896118;
    border-radius: 35px;
    cursor: pointer;
}
.loginPage_main__2BJgT{
    width: 100%;
    height: 65%;
}
.loginPage_main__2BJgT h2{
    font-size: 25px;
    padding-left: 10%;
    padding-top: 5%;
    font-weight: 600;
}
.loginPage_main__2BJgT form{
    margin-top: 10%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.loginPage_eyeIcon__2GmM_{
    position: absolute;
    top: 57%;
    right: 12%;
    width: 25px;
    cursor: pointer;
}
.loginPage_main__2BJgT label{
    margin-bottom: 1%;
    font-weight: 600;
    font-size: 17px;
}
.loginPage_main__2BJgT input{
    width: 90%;
    font-size: 17px;
    padding: 9px;
    margin-bottom: 5%;
    border: none;
    border: 2px solid #FBDD97;
    border-radius: 7px;
    background-color: white;
    outline: none;
}
.loginPage_main__2BJgT input:focus{
    border: 2px solid #896118;
}
.loginPage_main__2BJgT button{
    margin-top: 20px;
    width: 90%;
    font-size: 18px;
    padding: 5px;
    border: none;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.loginPage_main__2BJgT button:active{
    background-color: #896118;
    color: #FBDD97;
}
.loginPage_main__2BJgT p{
    font-size: 14px;
    text-align: center;
    margin-top: 2%;
}
.loginPage_main__2BJgT a{
    text-decoration: none;
    color: #896118;
}
.loginPage_footer__DEmEK{
    width: 100%;
    height: 12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loginPage_footer__DEmEK p{
    margin-bottom: 2%;
}
.loginPage_divider__1RrYk{
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginPage_divider__1RrYk div{
    width: 30%;
    height: 2px;
    background-color: rgb(154, 154, 154);
}
.loginPage_divider__1RrYk span{
    font-size: 13px;
    padding: 0px 10px;
}
.loginPage_forgotPassword__W43gk{
    margin-top: -15px;
    display: flex;
    width: 90%;
    font-size: 11px;
    justify-content: flex-end;
}
.homePage_container__3kQJo{
    width: 100%;
    height: 100vh;
    min-width: 1300px;
    min-height: 640px;
}
.homePage_navbar__173Xz{
    width: 100%;
    height: 10%;
    background-color: rgb(255, 255, 255);
    padding-left: 2%;
    display: flex;
    align-items: center;
    box-shadow: rgba(199, 199, 199, 0.861) 0px 2px 1px 0px, rgba(184, 184, 185, 0.906) 0px 1px 1px 1px;
}
.homePage_navbar__173Xz img{
    width: 48px;
    height: 43px;
}
.homePage_navbar__173Xz h2{
    margin-left: 10px;
}
.homePage_divider__2iqOI{
    width: 2px;
    height: 80%;
    background-color: #949494;
    margin-left: 3%;
    margin-right: 3%;
}
.homePage_navIcon__y-a59{
    width: 30px !important;
    height: 30px !important; 
}
.homePage_navbar__173Xz span{
    font-size: 16px;
    margin-right: 2%;
    margin-left: .5%;
    cursor: pointer;
    font-weight: 600;
}
.homePage_userBox__1FMNa{
    width: 200px;
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.homePage_user__3Wymx{
    font-size: 21px;
    background-color: #EB5D5D;
    color: white;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.homePage_userBox__1FMNa button{
    border: none;
    font-size: 17px;
    padding: 5px 20px;
    border-radius: 20px;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.homePage_main__aLetD{
    width: 100%;
    height: 90%;
}
.homePage_fileSection__3Yi-O{
    width: 50%;
    height: 170px;
    background-color: #FBDD97;
    margin: 3% auto;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(142, 142, 142, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.homePage_fileSectionChild__1hckP{
    width: 100%;
    height: 100%;
    border: 1px dotted #896118;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.homePage_uploadSection__1IgGj{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.homePage_upload__2zAgD{
    margin-top: 10px;
    border: none;
    font-size: 15px;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #896118;
    font-weight: 600;
    cursor: pointer;
}
a{
    text-decoration: none;
    color: black;
}
.homePage_setHashSection__1kGKt{
    width: 50%;
    height: 110px;
    background-color: #FBDD97;
    margin: 3% auto;
    border-radius: 5px;
    box-shadow: rgba(142, 142, 142, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.homePage_setHashSection__1kGKt h3{
    color: #896118;
    font-size: 17px;
}
.homePage_container__3kQJo button{
    display: flex;
    justify-content: center;
    align-items: center;
}
.homePage_container__3kQJo button:disabled{
    background-color: #ebebeb;
    color: rgb(169, 169, 169);
}
.homePage_getHashSection__3NM_T h3{
    color: #896118;
    font-size: 17px;
}
.homePage_getHashSection__3NM_T{
    width: 50%;
    height: auto;
    padding: 20px;
    background-color: #FBDD97;
    margin: 3% auto;
    border-radius: 5px;
    box-shadow: rgba(142, 142, 142, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.homePage_hashSection__3rB09{
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.homePage_hashSection__3rB09 input{
    border: none;
    font-size: 14px;
    padding: 3px 5px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #896118;
    color: #896118;
    font-weight: 600;
    outline: none;
    font-weight: 500;
}
.homePage_copyIcon1__3m3kd{
    width: 18px;
    position: absolute;
    top: 28%;
    left: 93%;
    cursor: pointer;
}
.homePage_copyIcon2__10S5D{
    width: 18px;
    position: absolute;
    top: 85%;
    left: 93%;
    cursor: pointer;
}
.homePage_getHashLabels__20RbN{
    font-size: 13px;
    font-weight: 600;
    color: #896118;
    margin-top: 10px;
    margin-bottom: -5px;
}
.homePage_walletStatus__31PTm{
    margin-left: 7px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.homePage_userStatus__2Uj2C{
    font-size: 11px !important;
    font-weight: 400 !important;
}
.homePage_accountAdd__V-8tT{
    color: rgb(0, 0, 0);
    font-size: 15px !important;
    font-weight: 400 !important;
    position: absolute;
    left: 45%;
}
.homePage_invalidFile__3fkVf{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.homePage_invalidFile__3fkVf img{
    width: 80px;
    height: 80px;
}
.homePage_divider2__1F8tP{
    width: 1px;
    height: 80%;
    background-color: #896118;
}
.homePage_invalidInfo__cUn1h p{
    color: #896118;
    font-weight: 600;
}
.homePage_invalidInfo__cUn1h button{
    margin-top: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    color: #896118;
    padding: 5px 9px;
    background-color: transparent;
    border: 2px solid #896118;
    border-radius: 5px;
    cursor: pointer;
}
.homePage_invalidInfo__cUn1h button img{
    width: 17px;
    height: 17px;
    margin-left: 5px;
}
.homePage_downloadFile__339YL{
    display: flex;
    justify-content: center;
    align-items: center;
}
.homePage_downloadFile__339YL button{
    border: none;
    font-size: 17px;
    padding: 10px 15px;
    border-radius: 3px;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.homePage_downloadFile__339YL img{
    width: 30px;
    margin-left: 10px;
}
input{
    padding-right: 43px !important;
}
.verifyPage_container__3_GJp{
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    min-height: 640px;
    background-size: 100% auto;
}
.verifyPage_navbar__2PGOM{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    padding-left: 2%;
    position: relative;
}
.verifyPage_navbar__2PGOM img{
    width: 48px;
    height: 43px;
}
.verifyPage_navbar__2PGOM h2{
    margin-left: 10px;
    cursor: pointer;
}
.verifyPage_navbar__2PGOM button{
    border: none;
    font-size: 17px;
    padding: 5px 20px;
    border-radius: 20px;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    right: 2%;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.verifyPage_section__1NwS-{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
}
.verifyPage_section1__2CAR2{
    height: 550px;
    flex: 0.35 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.verifyPage_section2__2r6_K{
    flex: 0.65 1;
    margin-top: -5%;
}
.verifyPage_section1__2CAR2 img{
    width: 400px;
}
.verifyPage_section2__2r6_K form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;
    padding-left: 20px;
}
form label img{
    width: 30px;
    margin-right: 10px;
}
form label{
    font-size: 21px;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
}
form input{
    width: 90%;
    border: none;
    font-size: 17px;
    padding: 7px;
    margin-top: 10px;
    border-radius: 5px;
    border: 2px solid #FBDD97;
    color: #896118;
    font-weight: 600;
    outline: none;
}
.verifyPage_validIcon__n2-AN{
    position: absolute;
    top: 145%;
    left: 86%;
    z-index: 200;
}
form input:focus{
    border: 2px solid #896118;
}
.verifyPage_ipfsSection__t88ta{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-top: -3.5%;
}
.verifyPage_ipfsSection__t88ta label img{
    width: 30px;
    margin-right: 10px;
}
.verifyPage_ipfsSection__t88ta label{
    font-size: 21px;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
}
.verifyPage_validIcon2__1hYff{
    position: absolute;
    top: 145%;
    left: 86%;
    z-index: 200;
}
.verifyPage_ipfsSection__t88ta input{
    width: 90%;
    border: none;
    font-size: 17px;
    padding: 7px;
    margin-top: 10px;
    border-radius: 5px;
    border: 2px solid #FBDD97;
    color: #896118;
    font-weight: 600;
    outline: none;
}
.verifyPage_ipfsSection__t88ta input:focus{
    border: 2px solid #896118;
}
.verifyPage_ipfsSection__t88ta button{
    width: 20%;
    border: none;
    font-size: 18px;
    padding: 5px;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.verifyPage_statusMessage__3E3j_{
    color: green;
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
}
.verifyPage_errorStatusMessage__3ats_{
    color: red;
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
}
input[readonly]
{
    background-color:rgb(233, 233, 233);
    color: rgb(121, 121, 121);
    border: 2px solid rgb(184, 184, 184);
}
button[readonly]
{
    background-color:rgb(233, 233, 233);
    color: rgb(121, 121, 121);
}
button:active{
    background-color: #896118;
    color: #FBDD97;
}
button:disabled{
    background-color:rgb(233, 233, 233);
    color: rgb(121, 121, 121);
    cursor: default;
}
.signUp_container__37jZh{
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    min-height: 640px;
    display: flex;
}
.signUp_section1__2QcVX{
    flex: 0.40 1;
} 
.signUp_section2__1t0kl{
    flex: 0.60 1;
    background-image: url(/static/media/page-background.3573b3e3.png);
    background-size: 110% auto;
    background-repeat: no-repeat;
    background-position: left center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signUp_section2__1t0kl img{
    width: 70%;
}
.signUp_section2__1t0kl span{
    font-size: 70px;
    color: #896118;
    font-weight: 700;
    margin-left: 20px;
}
.signUp_header__3Phxz{
    width: 100%;
    height: 13%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 10%;

}
.signUp_header__3Phxz img{
    width: 9%;
}
.signUp_header__3Phxz h2 {
    font-size: 25px;
    margin-left: 15px;
    font-weight: 700;
}
.signUp_verify__2kuOB{
    width: 100%;
    height: 10%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signUp_verify__2kuOB button{
    width: 80%;
    height: 70%;
    background-color: #896118;
    border: none;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    border: 2px solid #896118;
    border-radius: 35px;
    cursor: pointer;
}
.signUp_main__3QKIW{
    width: 100%;
    height: auto;
}
.signUp_main__3QKIW h2{
    font-size: 25px;
    padding-left: 10%;
    padding-top: 3.5%;
    font-weight: bold;
}
.signUp_main__3QKIW h4{
    padding-left: 10%;
    padding-top: 2%;
    font-weight: 600;
}
.signUp_main__3QKIW form{
    margin-top: 5%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}
.signUp_eyeIcon__1SD41{
    position: absolute;
    top: 51.5%;
    right: 12%;
    width: 25px;
    cursor: pointer;
}
.signUp_eyeIcon2___6MpK{
    position: absolute;
    top: 73%;
    right: 12%;
    width: 25px;
    cursor: pointer;
}
.signUp_main__3QKIW label{
    margin-bottom: 0%;
    font-weight: 600;
    font-size: 17px;
}
.signUp_main__3QKIW input{
    width: 90%;
    font-size: 17px;
    padding: 9px;
    margin-bottom: 4.4%;
    border: none;
    border: 2px solid #FBDD97;
    border-radius: 7px;
    background-color: white;
    outline: none;
}
.signUp_main__3QKIW input:focus{
    border: 2px solid #896118;
}
.signUp_main__3QKIW button{
    margin-top: 20px;
    margin-left: 50px;
    width: 50%;
    font-size: 18px;
    padding: 10px;
    border: none;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.632) 0px 3px 6px -1px, rgba(0, 0, 0, 0) 0px 2px 4px -1px;
}
.signUp_main__3QKIW button:active{
    background-color: #896118;
    color: #FBDD97;
}
.signUp_main__3QKIW p{
    font-size: 14px;
    text-align: center;
    margin-top: 2%;
}
.signUp_main__3QKIW a{
    text-decoration: none;
    color: #896118;
}
.signUp_signUp__DJqJk{
    display: flex;
    justify-content: center;
    margin: 2% 20% !important;
}
.signUp_footer__19PhA{
    width: 100%;
    height: 12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signUp_footer__19PhA p{
    margin-bottom: 2%;
}
.signUp_divider__e9Pej{
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signUp_divider__e9Pej div{
    width: 30%;
    height: 2px;
    background-color: rgb(154, 154, 154);
}
.signUp_divider__e9Pej span{
    font-size: 13px;
    padding: 0px 10px;
}
.signUp_forgotPassword__3T97Y{
    margin-top: -15px;
    display: flex;
    width: 90%;
    font-size: 11px;
    justify-content: flex-end;
}
.otpModal_section__31SYm{
    outline: none;
    border: none;
    display: flex;
    z-index: 100;
}
.otpModal_section1__3sipP{
    flex: 0.4 1;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 20px;
}
.otpModal_section1__3sipP h3{
    margin-top: 10px;
}
.otpModal_section1__3sipP span{
    font-size: 13px;
    margin-top: 10px;
}
.otpModal_otpInput__2BLLL input{
    height: 35px;
    margin-top: 25px;
    background-color: #D9D9D9;
    box-shadow: 0px 3px 3px rgb(172, 172, 172);
    border: none;
    border-radius: 5px;
    font-size: 17px !important;
    margin-right: 10px !important;
    color: #896118;
}
.otpModal_section1__3sipP button{
    margin-top: 25px;
    font-size: 16px;
    padding: 7px;
    border: none;
    background-color: #FBDD97;
    color: #896118;
    font-weight: 600;
    border-radius: 7px;
    cursor: pointer;
}
.otpModal_otpContainer__2amhd{
    display: flex !important;
    /* justify-content: space-around; */
}
.otpModal_sendOtpAgain__1BVCk{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}
.otpModal_sendOtpAgain__1BVCk h4{
    font-size: 13px;
    font-weight: 600;
}
.otpModal_sendOtpAgain__1BVCk p{
    font-size: 13px;
}
.otpModal_section2__11yTT{
    flex: 0.6 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.otpModal_section2__11yTT img{
    width: 100%;
}
button:active{
    background-color: #896118;
    color: #FBDD97;
}
